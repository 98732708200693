/* eslint-disable */
import $ from 'jquery';
import Cookies from 'js-cookie';
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut, sendPasswordResetEmail } from 'firebase/auth';
import { getFirestore, getDoc, updateDoc, onSnapshot, doc, setDoc, collection, getDocs, deleteDoc, query, where, limit, startAfter, orderBy, serverTimestamp, addDoc, arrayUnion, arrayRemove, deleteField, writeBatch, FieldPath, documentId } from 'firebase/firestore';
import { getDatabase, ref, get, child } from 'firebase/database';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDqckRbNiGenpibevuhKkztjHtPR1845uI",
  authDomain: "oliverlist-formations.firebaseapp.com",
  databaseURL: "https://oliverlist-formations-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "oliverlist-formations",
  storageBucket: "oliverlist-formations.appspot.com",
  messagingSenderId: "895370255847",
  appId: "1:895370255847:web:0c15094f47380410837142",
  measurementId: "G-JNJ5Y46981"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const rtdb = getDatabase(app);
const auth = getAuth();
const provider = new GoogleAuthProvider();
const functions = getFunctions(app);
const storage = getStorage(app);
const database = getDatabase(app);

function signInWithGoogle() {
  return signInWithPopup(auth, provider);
}

async function registerUser(email, password, userData) {
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  const userId = userCredential.user.uid;

  await setDoc(doc(db, 'users', userId), {
    ...userData,
    email,
    timestamp: serverTimestamp(),
  });

  return userCredential;
}

async function loginUser(email, password) {
  return signInWithEmailAndPassword(auth, email, password);
}

async function signOutUser() {
  return signOut(auth);
}

async function sendPasswordReset(email) {
  return sendPasswordResetEmail(auth, email);
}

async function getCompanyByName(companyName) {
  const companyQuery = query(collection(db, 'companies'), where('name', '==', companyName));
  const companySnapshot = await getDocs(companyQuery);
  
  return companySnapshot.empty ? null : companySnapshot.docs[0];
}

async function createCompany(companyData) {
  const companyRef = await addDoc(collection(db, 'companies'), companyData);
  return companyRef.id;
}

export {
  app,
  db,
  rtdb,
  auth,
  provider,
  signInWithGoogle,
  registerUser,
  loginUser,
  signOutUser,
  sendPasswordReset,
  getCompanyByName,
  createCompany,
  getDoc,
  doc,
  collection,
  getDocs,
  serverTimestamp,
  onAuthStateChanged,
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  getDatabase,
  ref,
  get,
  child,
  addDoc,
  query,
  where,
  updateDoc,
  arrayUnion,
  arrayRemove,
  getFunctions,
  deleteDoc,
  getStorage,
  storage,
  httpsCallable,
  functions,
  deleteField,
  writeBatch,
  orderBy,
  limit,
  startAfter,
  database,
  setDoc,
  onSnapshot,
  documentId,
  FieldPath
};