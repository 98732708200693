import $ from 'jquery';
import Cookies from 'js-cookie';
require('normalize.css/normalize.css');
require('../css/main.scss');
import 'regenerator-runtime/runtime';
import { db, getDoc, doc, auth, registerUser, getCompanyByName, createCompany, serverTimestamp, onAuthStateChanged, signOutUser, addDoc, collection, updateDoc, setDoc } from '../js/firebaseConfig';
import { checkAuthAndRedirect } from '../js/redirect.js';

$(document).ready(function() {
  /**********
   * 
   * 
   * 
   * 
   * DEMO ASSESSMENT 
   * 
   * 
   * 
   * 
   * **********/
  $('.trophy').on('click', function() {
    var $trophy = $(this);
    $trophy.addClass('vibrate');
    setTimeout(function() {
        $trophy.removeClass('vibrate');
    }, 300);
  });

  function formatTime(timestamp) {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }
  function formatDuration(durationString) {
    const durationMinutes = parseInt(durationString, 10);
    return durationMinutes;
  }
  window.addEventListener('message', async (event) => {
    if (event.origin === 'https://tally.so') {
      try {
        if (!event.data.startsWith('{')) {
          return;
        }
        const eventData = JSON.parse(event.data);
        if (eventData.event === 'Tally.FormSubmitted') {
          var formData = eventData.payload;
          var filteredData = formData.fields.filter(field => 
            field.title === 'Votre email professionnel' ||
            field.title === 'Nom de votre entreprise'
          );
          var filteredDataObject = {};
          filteredData.forEach(field => {
            if (field.title === 'Votre email professionnel') {
              filteredDataObject.email = field.answer.value;
            } else if (field.title === 'Nom de votre entreprise') {
              filteredDataObject.company = field.answer.value;
            }
          });
          Cookies.set('formData', JSON.stringify(filteredDataObject), {
            path: '/',
            sameSite: 'Lax',
            secure: false,
            expires: 1
          });
          onAuthStateChanged(auth, (user) => {
            window.location.href = 'https://www.salesridge.co/demo/assessment.html';
          });
        }
      } catch (error) {
        console.error('Erreur lors de l\'analyse des données de l\'événement:', error);
      }
    }
    if (event.origin === 'https://meetings-eu1.hubspot.com') {
      try {
        const eventData = event.data;
        if (eventData.meetingBookSucceeded) {
          const meetingsPayload = eventData.meetingsPayload;
          const bookingResponse = meetingsPayload.bookingResponse;
          const eventDetails = bookingResponse.event;
          const companyId = Cookies.get('companyId');
          async function addAppointment() {
            try {
              const appointmentRef = await addDoc(collection(db, 'appointments'), {
                companyId: companyId,
                date: eventDetails.dateString,
                duration: formatDuration(eventDetails.duration),
                time: formatTime(eventDetails.dateTime)
              });
              $('<div class="notificationsRDV"><p>Rendez-vous pris avec succès.</p></div>').prependTo('body .plan');
              $('body').removeClass('bookMeeting');
            } catch (e) {
              console.error("Error adding document: ", e);
            }
          }
          await addAppointment();
        }
      } catch (error) {
        console.error('Erreur lors de la conversion du message en JSON:', error);
      }
    }
  });
  $(document).on("click", "#startAssessment", function() {
    $('body').addClass('startAssessment');
  });
  //MOBILE
  $('#mobileMenu').on('click', function() {
    event.preventDefault();
    $('body').addClass('navOpen');
  });
  $('#navBackground').on('click', function() {
    event.preventDefault();
    $('body').removeClass('navOpen');
  });
  //HUBSPOT
  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'hs-script-loader';
  script.async = true;
  script.defer = true;
  script.src = '//js.hs-scripts.com/26298630.js';
  $('body').append(script);
  //TABS
  $('.tab-links a').on('click', function(e) {
    e.preventDefault();
    var tabId = $(this).attr('href');
    $('.tab-links li, .tab').removeClass('active');
    $(this).parent('li').addClass('active');
    $(tabId).addClass('active');
  });
  $(document).on('click', '.tab-links2 a', async function(e) {
    e.preventDefault();
    var tabId = $(this).attr('href');
    $('.tab-links2 li, .tab2').removeClass('active');
    $(this).parent('li').addClass('active');
    $(tabId).addClass('active');
  });
  //STATUSSEQUENCESMODAL
  $(".statusSequencesModal input").on("keyup", function() {
    let searchText = $(this).val().toLowerCase();
    $(".statusSequencesModal p").each(function() {
        let text = $(this).text().toLowerCase();
        if (text.includes(searchText)) {
            $(this).show();
        } else {
            $(this).hide();
        }
    });
  });
  $(document).on("click", function(event) {
    if (!$(event.target).closest(".statusSequencesModal, .statusSequences").length) {
      $(".statusSequencesModal").removeClass("active");
      $(".statusSequencesModal input").val('');
      $(".statusSequencesModal p").each(function() {
        $(this).show();
      });
    }
  });
  $(document).on('click', '.statusSequences', async function(event) {
    $(".statusSequencesModal input").val('');
    $(".statusSequencesModal p").each(function() {
      $(this).show();
    });
    let modal = $(".statusSequencesModal");
    let windowHeight = $(window).height();
    let windowWidth = $(window).width();
    let modalHeight = modal.outerHeight();
    let modalWidth = modal.outerWidth();
    let clickX = event.pageX;
    let clickY = event.pageY;

    let posX = clickX;
    let posY = clickY;

    // GESTION DU POSITIONNEMENT VERTICAL
    if (clickY + modalHeight > windowHeight - 20) { 
        posY = clickY - modalHeight - 10;
    } else { 
        posY = clickY + 10;
    }

    // GESTION DU POSITIONNEMENT HORIZONTAL
    if (clickX + modalWidth > windowWidth - 20) { 
        posX = clickX - modalWidth - 10;
    } else { 
        posX = clickX + 10;
    }

    // Associer la modal au bon élément cliqué
    modal.data("target", $(this));

    modal.css({
        "top": posY + "px",
        "left": posX + "px",
    }).addClass("active");
  });
  

  $(document).on("click", ".statusSequencesModal p", async function () {
    let newStatusText = $(this).text().trim(); 
    let newStatus = $(this).attr('data-value'); 
    let target = $(".statusSequencesModal").data("target");

    if (!target) {
        console.error("❌ Erreur : aucun élément statusSequences associé à la modal.");
        return;
    }

    let leadId = target.data("lead");
    let campaignId = target.data("campaign");
    let stepIndex = target.data("step");

    if (!leadId || !campaignId || stepIndex === undefined) {
        console.error("❌ Erreur : Données manquantes (leadId, campaignId ou stepIndex)");
        return;
    }

    target.text(newStatusText).removeClass().addClass(`statusSequences ${newStatusText}`);

    const leadRef = doc(db, "leads", leadId);
    getDoc(leadRef)
        .then((leadSnap) => {
            if (leadSnap.exists()) {
                let leadData = leadSnap.data();
                
                if (leadData.campaigns && leadData.campaigns[campaignId] && Array.isArray(leadData.campaigns[campaignId].steps)) {
                    let steps = leadData.campaigns[campaignId].steps;

                    if (steps[stepIndex]) {
                        steps[stepIndex].status = newStatus;
                        updateDoc(leadRef, {
                            [`campaigns.${campaignId}.steps`]: steps
                        }, { merge: true })
                        .then(() => {
                            console.log(`✅ Statut mis à jour : ${newStatus}`);
                        })
                        .catch((error) => {
                            console.error("❌ Erreur de mise à jour Firestore :", error);
                        });
                    } else {
                        console.error("❌ Erreur : Step index non trouvé.");
                    }
                } else {
                    console.error("❌ Erreur : Campagne ou steps non trouvés.");
                }
            }
        })
        .catch((error) => {
            console.error("❌ Erreur de récupération du lead :", error);
        });

    // Fermer la modal après mise à jour
    $(".statusSequencesModal").removeClass("active");
  });



});